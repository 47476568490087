export enum Language {
  English = 'en',
  Thai = 'th',
}

export enum LanguageName {
  en = 'English',
  th = 'ภาษาไทย',
}

export enum Namespace {
  HeaderFooter = 'headerfooter',
  Registration = 'registration',
  Feedback = 'feedback',
  Landing = 'landing',
  TermsAndConditions = 'termsconditions',
  About = 'about',
  FAQ = 'faq',
  Contribution = 'contribution',
}
