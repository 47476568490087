export enum COLLECTION_TYPE {
  chronological = 'CHRONOLOGICAL_COLLECTION',
  nonchronological = 'NONCHRONOLOGICAL_COLLECTION',
}

export enum CHRONOLOGICAL_COLLECTION {
  educationalContentLog = 'educationalContentLog',
  encouragementMessageLog = 'encouragementMessageLog',
  handWashingTransactions = 'handWashingTransactions',
  tipOfTheDayFeedbacks = 'tipOfTheDayFeedbacks',
  tipOfTheDaySendingTransactions = 'tipOfTheDaySendingTransactions',
  uncaughtUserInputMessageLog = 'uncaughtUserInputMessageLog',
  userStateTransactions = 'userStateTransactions',
}

export enum NONCHRONOLOGICAL_COLLECTION {
  onBoardingStatus = 'onBoardingStatus',
  userExperimentGroup = 'userExperimentGroup',
  users = 'users',
  usersSetting = 'usersSetting',
  usersState = 'usersState',
}

export const COLLECTION = {
  ...CHRONOLOGICAL_COLLECTION,
  ...NONCHRONOLOGICAL_COLLECTION,
}

export type COLLECTION = CHRONOLOGICAL_COLLECTION | NONCHRONOLOGICAL_COLLECTION
