import firebase from 'gatsby-plugin-firebase'
import { Language } from '../constants/i18n'
import { COLLECTION } from '../constants/collections'

export const getUsersCollection = (): firebase.firestore.CollectionReference<
  firebase.firestore.DocumentData
> => firebase.firestore().collection(COLLECTION.users)
export const getUsersSettingCollection = (): firebase.firestore.CollectionReference<
  firebase.firestore.DocumentData
> => firebase.firestore().collection(COLLECTION.usersSetting)
export const getEducationalContentLogCollection = (): firebase.firestore.CollectionReference<
  firebase.firestore.DocumentData
> => firebase.firestore().collection(COLLECTION.educationalContentLog)

export const getUserLanguageSetting = async (
  userId: string,
): Promise<Language> => {
  const userSetting = await getUsersSettingCollection().doc(userId).get()
  return userSetting.data()?.language || Language.English
}

export const withTimestampField = (
  data: Record<string, any>,
  fieldName = 'timestamp',
): Record<string, any> => ({
  ...data,
  [fieldName]: firebase.firestore.FieldValue.serverTimestamp(),
})

export async function getAllRecordFromCollection(
  collection: COLLECTION,
  dateRange?: {
    from: Date
    to: Date
  },
): Promise<Array<Record<string, unknown>>> {
  let query: firebase.firestore.Query = firebase
    .firestore()
    .collection(collection)

  if (dateRange) {
    const { from, to } = dateRange
    query = query.where('created', '>=', from).where('created', '<=', to)
  }

  const snapshot = await query.get()
  const res = []

  snapshot.forEach((doc) => {
    res.push({ _id: doc.id, ...doc.data() })
  })

  return res
}
