import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import {
  CHRONOLOGICAL_COLLECTION,
  COLLECTION_TYPE,
  NONCHRONOLOGICAL_COLLECTION,
  COLLECTION,
} from '../constants/collections'
import { getAllRecordFromCollection } from './firestore'
import { parseCSVfromObject } from './csv'

const TIMEZONE_DIFF = 7

export async function exportDatabase(
  collectionType: Record<COLLECTION_TYPE, boolean>,
  from: string,
  to: string,
): Promise<void> {
  const fromDate = new Date(from)
  const toDate = new Date(to)

  fromDate.setUTCHours(-TIMEZONE_DIFF)
  toDate.setUTCHours(23 - TIMEZONE_DIFF, 59, 59)

  const fileName = `data_${from}_${to}.zip`
  const zip = new JSZip().folder(fileName)

  const collections: COLLECTION[] = [
    ...(collectionType[COLLECTION_TYPE.chronological]
      ? Object.values(CHRONOLOGICAL_COLLECTION)
      : []),
    ...(collectionType[COLLECTION_TYPE.nonchronological]
      ? Object.values(NONCHRONOLOGICAL_COLLECTION)
      : []),
  ]

  await Promise.all(
    collections.map(async (collection) => {
      const data = await getAllRecordFromCollection(
        collection,
        CHRONOLOGICAL_COLLECTION[collection] && {
          from: fromDate,
          to: toDate,
        },
      )
      await zip.file(`${collection}.csv`, parseCSVfromObject(data))
    }),
  )

  const zipContent = await zip.generateAsync({ type: 'blob' })

  saveAs(zipContent, fileName)
}
