import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { signOut, useAuthValidation } from '../../utils/auth'
import { exportDatabase } from '../../utils/data-export'
import Layout from '../../components/layouts/Layout'
import {
  COLLECTION_TYPE,
  CHRONOLOGICAL_COLLECTION,
  NONCHRONOLOGICAL_COLLECTION,
} from '../../constants/collections'

const redirectToAdminLoginPage = () => {
  window.location.pathname = '/admin'
}

const camelCaseToNormalCase = (text: string) =>
  text.replace(/([A-Z])/g, ' $1').toLowerCase()

const CollectionInputGroup = ({
  children,
  collectionType,
  label,
}: {
  children?: JSX.Element | JSX.Element[]
  collectionType: COLLECTION_TYPE
  label: string
}): JSX.Element => (
  <div className="flex flex-col border border-blue-200 p-8 rounded space-y-2">
    <div className="space-y-2">
      <div className="flex flex-row">
        <Field
          id={collectionType}
          name={collectionType}
          type="checkbox"
          placeholder={label}
          aria-label={label}
          className="my-auto mr-2"
        />
        <label
          htmlFor={collectionType}
          className="block text-xl leading-5 font-bold text-blue-800"
        >
          {label}
        </label>
      </div>
      <ul className={`list-disc text-sm text-gray-600 pl-12`}>
        {Object.values(
          collectionType === COLLECTION_TYPE.chronological
            ? CHRONOLOGICAL_COLLECTION
            : NONCHRONOLOGICAL_COLLECTION,
        ).map((collection) => (
          <li key={collection}>{camelCaseToNormalCase(collection)}</li>
        ))}
      </ul>
    </div>
    {children}
  </div>
)

const DateInput = ({
  id,
  label,
  disabled,
}: {
  id: string
  label: string
  disabled: boolean
}): JSX.Element => (
  <div className={disabled ? 'opacity-50' : ''}>
    <label
      htmlFor={id}
      className="block text-sm leading-5 font-medium text-gray-700"
    >
      {label}
    </label>
    <Field
      id={id}
      name={id}
      type="date"
      required
      disabled={disabled}
      className="bg-white w-full focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal"
      placeholder={label}
      aria-label={label}
    />
  </div>
)

function DataExport(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true)

  useAuthValidation(() => setIsLoading(false), redirectToAdminLoginPage)

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <Layout className="min-h-screen flex bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg w-full mx-auto">
        <h2 className="mb-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Data Export Tool
        </h2>

        <Formik
          initialValues={{
            from: '',
            to: '',
            [COLLECTION_TYPE.chronological]: false,
            [COLLECTION_TYPE.nonchronological]: false,
          }}
          onSubmit={async ({ from, to, ...collectionType }) => {
            await exportDatabase(collectionType, from, to)
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="flex flex-col space-y-6 mb-6">
                <CollectionInputGroup
                  collectionType={COLLECTION_TYPE.chronological}
                  label="Chronological Data"
                >
                  <div className="flex flex-row space-x-4">
                    <DateInput
                      id="from"
                      label="From"
                      disabled={!values[COLLECTION_TYPE.chronological]}
                    />
                    <DateInput
                      id="to"
                      label="To"
                      disabled={!values[COLLECTION_TYPE.chronological]}
                    />
                  </div>
                </CollectionInputGroup>
                <CollectionInputGroup
                  collectionType={COLLECTION_TYPE.nonchronological}
                  label="Non-Chronological Data"
                />
                <div className="text-center">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-blue-300 inline-flex items-center"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !(
                        values[COLLECTION_TYPE.chronological] ||
                        values[COLLECTION_TYPE.nonchronological]
                      )
                    }
                  >
                    <svg
                      className="fill-current w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    {isSubmitting ? 'Exporting...' : 'Export'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="text-center">
          <button
            className="text-blue-700 hover:underline"
            onClick={() => signOut(redirectToAdminLoginPage)}
          >
            Logout
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default DataExport
