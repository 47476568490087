export function parseRecordField(key: string, record: unknown): unknown {
  return record[key] ? record[key].seconds || record[key] : ''
}

export function parseCSVfromObject(
  documents: Array<Record<string, unknown>>,
): string {
  const header = [
    ...new Set(
      [
        ...documents.reduce(
          (keyList, record) => [...keyList, ...Object.keys(record)],
          [],
        ),
      ].sort(),
    ),
  ]

  const rows = [
    header,
    ...documents.map((record) =>
      header.map((key) => parseRecordField(key, record)),
    ),
  ]

  return rows.map((record) => record.join(',')).join('\n')
}
